import processJsonErrors from "./lib/bootstrap-errors";
import {svgTemplateToIcon} from "./lib/map-tools";
import {geocodeFullAddress} from "./lib/geocoder";
import {addMap} from "./phab-mapping";

export default function professional() {
    let mapElement = document.getElementById('phabProfessionalMap');
    let map;
    let salonMarker;
    let $locationMarkerWarning = $('#locationMarkerWarning');
    let submitButton = document.querySelector('input[type="submit"]')

    function updateLatlngWith(lat, lng) {
        document.getElementById('professional_lng').value = lng;
        document.getElementById('professional_lat').value = lat;
        $locationMarkerWarning.addClass('d-none');
        submitButton.disabled = false;
    }

    if (mapElement) {
        // defaults to the centre of the UK
        let latLngToUse = mapElement.dataset.lat ? [mapElement.dataset.lat, mapElement.dataset.lng] : [54.09, -2.894];
        let alreadyHasLatLng = (mapElement.dataset.lat !== "");

        map = L.map('phabProfessionalMap').setView(latLngToUse, alreadyHasLatLng ? 17 : 5);
        addMap(map);
        L.tileLayer('https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png', {
            maxZoom: 19,
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Tiles style by <a href="https://www.hotosm.org/" target="_blank">Humanitarian OpenStreetMap Team</a> hosted by <a href="https://openstreetmap.fr/" target="_blank">OpenStreetMap France</a>'
        }).addTo(map);

        salonMarker = L.marker(latLngToUse,
            {icon: L.icon({iconUrl: svgTemplateToIcon('professionalIcon'), iconSize: [32, 32]})});

        if (mapElement.dataset.editable) {
            salonMarker.options.draggable = true;
            salonMarker.options.autoPan = true;
            salonMarker.addEventListener('move', function (e) {
                updateLatlngWith(e.latlng.lat, e.latlng.lng);
            });
        }

        if (alreadyHasLatLng) {
            salonMarker.addTo(map);
        }
    }

    let form = document.getElementById('phab-professional-edit')
    if (form) {
        salonMarker.addTo(map);

        let salonAddress = document.getElementById('professional_salon_address');
        let salonTown = document.getElementById('professional_salon_town');
        let salonPostcode = document.getElementById('professional_salon_postcode');

        function updateSalonMap(e) {
            if ([salonAddress, salonTown, salonPostcode].includes(e.relatedTarget)) {
                // while any of them are still active, don't try to update the map.
                // We only want to do that when the user is done updating their address.
                return;
            }

            if (salonAddress.value && salonTown.value && salonPostcode.value) {
                geocodeFullAddress(salonAddress.value, salonTown.value, salonPostcode.value, function (data) {
                    if (data === false) {
                        alert("That address doesn't seem to exist. Please check it. If it is correct, please drag the marker on the map manually to locate your salon.");
                        mapElement.scrollIntoView();
                        $locationMarkerWarning.removeClass('d-none');
                        submitButton.disabled = true;
                    } else {
                        let latLng = [data.lat, data.lon];
                        salonMarker.setLatLng(latLng);
                        salonMarker.addTo(map);
                        map.setView(latLng, 17);
                        updateLatlngWith(data.lat, data.lon);
                    }
                });
            }
        }

        [salonAddress, salonTown, salonPostcode].forEach(function (el) {
            el.addEventListener('focusout', updateSalonMap)
        });

        const $adv = $("#professional_advanced_services");
        $adv && $adv.on("change", () => {
            const $selectedOptions = $adv.find('option:selected');
            const table = $('#adv-services-table');

            if ($selectedOptions.length === 0) {
                table.hide();
            } else {
                table.show();
            }

            $adv.find('option:not(:selected)').each((_, selected) => {
                const disable = $('#advanced-' + selected.dataset.slug);
                disable.hide();
                disable.find('input').prop('required', false);
            });
            $selectedOptions.each((_, selected) => {
                const enable = $('#advanced-' + selected.dataset.slug);
                enable.show();
                enable.find('input').prop('required', true);
            });
        });

        form.addEventListener('ajax:success', function () {
            document
                .getElementById('professional_alerts')
                .innerHTML = document.getElementById('professional_success').innerHTML;
            window.scrollTo(0, 0);
        })
        form.addEventListener('ajax:error', function (e) {
            processJsonErrors('professional', e, function (erroredField) {
                // if the field can't be found, this callback runs to specify a field
                if (erroredField === 'profile_photo') {
                    return document.getElementById('phab-profile-photo')
                }
            })
        });
    }
}