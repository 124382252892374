let maps = []

export default function mapping() {
    $('[data-collapse-invalidate-maps]').on('shown.bs.collapse', function () {
        maps.forEach(function (map) {
            map.invalidateSize();
        });
    });
}

export function addMap(map) {
    maps.push(map);
}