import geocode from "./lib/geocoder";
import "leaflet/dist/leaflet.js";
import {svgTemplateToIcon} from "./lib/map-tools";
import {addMap} from "./phab-mapping";

export default function search() {
    let phabSearch = $('.phab-search');
    if (phabSearch.length) {
        // If the browser supports the Geolocation API, let them use it
        if (navigator.geolocation) {
            let inputGroup = $(".input-group-phab-location");
            $("#location_locate").click(function () {
                navigator.geolocation.getCurrentPosition(function (position) {
                    submitSearchWithLocation(phabSearch[0], position.coords.latitude, position.coords.longitude);
                }, function (error) {
                    inputGroup.removeClass("phab-location-enabled");
                    // If the error is that we were denied permission, the user already knows what happened,
                    // because they denied the permission. Duh. No need to warn them about it.
                    if (error.code !== error.PERMISSION_DENIED) {
                        alert("Sorry, we couldn't find your location. Please try typing in your town or postcode manually.");
                    }
                });
            });
            inputGroup.addClass("phab-location-enabled");
        }

        // If we're submitting otherwise, process the submit with Nominatim
        phabSearch.submit(function (e) {
            e.preventDefault();
            geocode(document.getElementById("location").value, function (data) {
                if (data === false) {
                    alert("Hrm, we couldn't find that location. Please try entering a more specific location, or your town if you were using your postal code.");
                } else {
                    submitSearchWithLocation(phabSearch[0], data.lat, data.lon);
                }
            })
        });
    }

    let mapElement = document.getElementById('phabSearchMap');
    if (mapElement) {
        let params = new URLSearchParams(window.location.search);
        let map = L.map('phabSearchMap').setView([params.get('lat'), params.get('lng')], 13);
        addMap(map);

        L.tileLayer('https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png', {
            maxZoom: 19,
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Tiles style by <a href="https://www.hotosm.org/" target="_blank">Humanitarian OpenStreetMap Team</a> hosted by <a href="https://openstreetmap.fr/" target="_blank">OpenStreetMap France</a>'
        }).addTo(map);

        let markers = new L.featureGroup();
        L.marker([params.get('lat'), params.get('lng')],
            {icon: L.icon({iconUrl: svgTemplateToIcon('geolocIcon'), iconSize: [16, 16]})})
            .addTo(markers).addTo(map)
        let proIcon = L.icon({iconUrl: svgTemplateToIcon('professionalIcon'), iconSize: [24, 24]});
        $(".phab-professional-search-card").each(function () {
            L.marker([this.dataset.lat, this.dataset.lng], {icon: proIcon})
                .addTo(markers).addTo(map)
                .bindPopup('<strong><a href="' + this.dataset.link + '">' + this.dataset.name +
                    '</a></strong><br>' + this.dataset.salon);
        })
        map.fitBounds(markers.getBounds());
    }
}

function submitSearchWithLocation(search, lat, lng) {
    document.getElementById("lat").value = lat;
    document.getElementById("lng").value = lng;
    Turbolinks.visit(search.action + "?" + $(search).serialize());
}