// Manages prices and VAT on a checkout page. Call setupPriceManager to start.
// The container for the priceManager must have data-base-price and data-vat set.
// data-base-price is the base price for the product.
// data-vat is the percentage amount of VAT to collect.

export default class PhabPriceManager {
    constructor(container) {
        this.basePrice = Number(container.dataset.basePrice);
        this._addonPrice = 0;
        this._discountAmount = 0;
        this.vatPercentage = (Number(container.dataset.vat) / 100) // express it as a decimal

        let $container = $(container)

        this.$totalNoVat = $container.find('#totalNoVat');
        this.$grandTotal = $container.find('#grandTotal');
        this.$vatTotal = $container.find('#vatTotal');
        this.$discountsRow = $container.find('#discountsRow');
        this.$discountsCell = $container.find('#discountsCell');
    }

    updateTotals() {
        this._totalWithoutVat = (this.basePrice + this._addonPrice - this._discountAmount);
        this.$totalNoVat.html(numberToHumanPrice(this._totalWithoutVat + this._discountAmount));
        this.$vatTotal.html(numberToHumanPrice(this.vatTotal()));
        this.$grandTotal.html(numberToHumanPrice(this.grandTotal()));
    }

    grandTotal() {
        return this._totalWithoutVat + this.vatTotal();
    }

    vatTotal() {
        return this._totalWithoutVat * this.vatPercentage;
    }

    addAddon(price) {
        this._addonPrice += price;
        this.updateTotals();
    }

    removeAddon(price) {
        this._addonPrice -= price;
        if (this._addonPrice < 0) {
            // should never happen, but just a safeguard
            this._addonPrice = 0;
        }
        this.updateTotals();
    }

    setDiscount(price) {
        this._discountAmount = price;
        this.updateDiscountRow();
        this.updateTotals();
    }

    updateDiscountRow() {
        if (this._discountAmount === 0) {
            this.$discountsRow.fadeOut();
        } else {
            this.$discountsCell.html(numberToHumanPrice(this._discountAmount));
            this.$discountsRow.hide().removeClass('d-none').fadeIn();
        }
    }
}

function numberToHumanPrice(number) {
    return "£" + number.toFixed(2);
}