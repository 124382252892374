export default function carousel() {
    let youtubeCarousels = $('.phab-youtube-carousel');

    if (youtubeCarousels.length) {
        if (document.getElementById('yt-iframe-api')) {
            setupYoutubeCarousels();
        } else {
            let tag = document.createElement('script');
            tag.id = 'yt-iframe-api';
            tag.src = 'https://www.youtube.com/iframe_api';
            let firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
            window.onYouTubeIframeAPIReady = setupYoutubeCarousels;
        }

        function setupYoutubeCarousels() {
            youtubeCarousels.each(function () {
                let $this = $(this);

                let onPlayerStateChange = function (event) {
                    if ([-1, 1, 5].includes(event.data)) {
                        // unstarted, playing, cued
                        $this.carousel('pause');
                    } else if (event.data !== 3) {
                        // it's neither playing, nor buffering, nor cued
                        // it must be unstarted, ended or paused
                        $this.carousel('cycle');
                    }
                }

                $this.find('div.embed-responsive-item').each(function () {
                    new window.YT.Player(this, {
                        host: 'https://www.youtube-nocookie.com',
                        videoId: this.dataset.ytVideo,
                        playerVars: {
                            modestbranding: 1,
                            autohide: 1,
                            rel: 0,
                            origin: 'https://phabservicestars.com'
                        },
                        events: {
                            'onStateChange': onPlayerStateChange
                        }
                    });
                });
            })
        }
    }
}