import processJsonErrors from "./lib/bootstrap-errors";

export default function testimonials() {
    let form = document.getElementById('customer_testimonial_form');
    if (form) {
        // Handle successful form submission
        form.addEventListener('ajax:success', function () {
            form.outerHTML = document.getElementById('customer_testimonial_success').innerHTML;
        })

        // Handle failed form submission
        form.addEventListener('ajax:error', function (e) {
            processJsonErrors('customer_testimonial', e)
        });
    }
}