import {loadStripe} from '@stripe/stripe-js/pure';
import PhabPriceManager from "./lib/phab-price-manager";

export default async function payments() {
    let paymentsForm = document.getElementById('checkoutPaymentsForm');
    let changePaymentMethodLink = document.getElementById('phab-change-payment-method');
    let launchesCheckoutOnClick = document.querySelectorAll('[data-launch-checkout]');
    let cardConfirmPayment = document.querySelectorAll('[data-payment-confirm]');
    if (paymentsForm || changePaymentMethodLink || launchesCheckoutOnClick.length) {
        const stripe = await loadStripe("pk_live_51H8q1EKLdHRgLzAoiAxdWbWCBDzJRM8jk7wL2NeHmZlMyeUWInPOOLoejJEBrD734lzgGerGLq5o0lPILOesaVIK00cfDdT2Nz");

        if (changePaymentMethodLink) {
            changePaymentMethodLink.addEventListener('ajax:success', function (e) {
                processCheckoutEvent(stripe, e)
            })
        }

        $(launchesCheckoutOnClick).click(function (e) {
            e.preventDefault();
            stripe.redirectToCheckout({
                sessionId: this.dataset.checkoutSessionId
            }).then(function (result) {
                alert(result.error.message);
            });
        });

        $(cardConfirmPayment).click(function (e) {
            e.preventDefault();

            let clickRecipient = this;
            clickRecipient.classList.add('disabled');

            stripe.confirmCardPayment(this.dataset.paymentConfirm, {
                payment_method: this.dataset.paymentMethod
            }).then(function (result) {
                if (result.error) {
                    // Show error to your customer
                    alert(result.error.message);
                    clickRecipient.classList.remove('disabled');
                } else {
                    if (result.paymentIntent.status === 'succeeded') {
                        alert("Thank you! Payment complete. Your profile is being published!");
                        window.location = "/";
                    }
                }
            });
        });

        let discountBox = $('#discount_code');
        let discountInfoArea = discountBox.parent().find('.input-group-append .input-group-text');
        let discountCallback = function () {
        };

        let processDiscountCode = function (thisBox) {
            if (thisBox.value) {
                let discountAmount = 0;
                $.ajax({
                    url: "/discounts/" + thisBox.value
                }).done(function (data) {
                    discountBox.removeClass('is-invalid');
                    discountBox.addClass('is-valid');
                    discountAmount = data;
                    discountInfoArea.addClass('text-success').html(discountAmount + ' discount');
                }).fail(function (jqXHR) {
                    discountBox.removeClass('is-valid');
                    discountBox.addClass('is-invalid');

                    let error = '';
                    switch (jqXHR.status) {
                        case 404:
                            error = 'Invalid discount code'
                            break
                        case 410:
                            error = 'Discount code expired'
                            break
                        default:
                            error = 'Unknown error'
                    }

                    discountInfoArea.removeClass('text-success').html(error);
                }).always(function () {
                    discountInfoArea.show();
                    discountCallback(discountAmount);
                });
            } else {
                discountBox.removeClass('is-invalid');
                discountInfoArea.hide();
                discountCallback(null);
            }
        };

        processDiscountCode(discountBox[0]);

        discountBox.change(function () {
            processDiscountCode(this);
        });

        if (paymentsForm) {
            paymentsForm.addEventListener('ajax:success', function (e) {
                processCheckoutEvent(stripe, e)
            });

            let pm = new PhabPriceManager(paymentsForm);

            $('.price-addon').change(function () {
                let thisPrice = Number(this.dataset.price);
                if (this.checked) {
                    pm.addAddon(thisPrice);
                } else {
                    pm.removeAddon(thisPrice);
                }
            });

            discountCallback = function (amount) {
                if (amount === null) {
                    pm.setDiscount(0);
                } else {
                    // passing this without a wrapping function causes an error about `this` being undefined.
                    // JS is weird...
                    // We remove the £ sign from the amount with a substr(1).
                    pm.setDiscount(Number(amount.substr(1)));
                }
            };
        }
    }
}

async function processCheckoutEvent(stripe, event) {
    stripe.redirectToCheckout({
        sessionId: event.detail[0].session_id
    }).then(function (result) {
        alert(result.error.message);
    });
}