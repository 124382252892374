// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import '../lib/core.js.erb'

// custom js
import turbolinksGetForms from "../lib/turbolinks-forms";
import search from "../phab-search";
import apply from "../phab-apply";
import payments from "../phab-payments.js.erb";
import testimonials from "../phab-testimonials";
import managers from "../phab-managers";
import masonry from "../phab-masonry";
import carousel from "../phab-carousel";

document.addEventListener("turbolinks:load", function () {
    turbolinksGetForms();
    search();
    masonry();
    apply();
    payments();
    testimonials();
    managers();
    carousel();
});

// stylesheets
import "@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4.css";