// Lets us test with the jQuery object in tests, whilst not ruining everything in production.


require("@rails/ujs").start();
require("turbolinks").start();

import "bootstrap";
import "select2/dist/js/select2";
import 'nodep-date-input-polyfill';

import professional from "../phab-professional.js";

document.addEventListener("turbolinks:load", function () {
    $("select").select2({theme: 'bootstrap4'});
    professional();

    // Automatically add protocol to URLs where possible
    $("input[type='url']").change(function () {
        if (!/^https?:\/\//.test(this.value)) {
            this.value = "https://" + this.value;
        }
    })
});

import "../stylesheets/application.scss";
import "leaflet/dist/leaflet.css";
import "select2/dist/css/select2.css";
import "@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4.css";