// Processes errors in a {property: [errors]} format in JSON from a Rails AJAX callback.
// Accepts a callback parameter - if it's specified, then it can be used to find custom fields, that don't
// have the standard Rails form field naming scheme.
export default function processJsonErrors(model, event, callbackForField) {
    // event.detail contains [response, status, xhr] per
    // https://guides.rubyonrails.org/working_with_javascript_in_rails.html#remote-elements
    let jsonErrors = event.detail[0];
    let erroredKeys = Object.keys(jsonErrors);
    erroredKeys.forEach(function (erroredField, index) {
        let fieldNameOnPage = erroredField;
        if (erroredField === "words_in_bio") {
            fieldNameOnPage = "bio";
        }

        let field = document.getElementById(model + '_' + fieldNameOnPage);
        if (!field && callbackForField) {
            field = callbackForField(fieldNameOnPage)
        }

        if (field) {
            let jField = $(field);
            jField.addClass('is-invalid');
            let fieldError = $('<div class="invalid-feedback">' +
                jsonErrors[erroredField].join("<br />")
                + '</div>');
            fieldError.insertAfter(jField);

            // remove the error once the user has acknowledged it
            jField.click(function () {
                fieldError.remove();
                jField.removeClass('is-invalid');
                // no need to keep monitoring this change event, that's silly
                jField.off('change');
            });

            if ((index + 1) === erroredKeys.length) {
                // last error gets scrolled into the viewport
                field.scrollIntoView();
            }
        }
    });
}