import processJsonErrors from "./lib/bootstrap-errors";

export default function managers() {
    let form = document.getElementById('manager_form');
    if (form) {
        let realValues = $('#real-values');
        let inaccurateRadioButton = $('#manager_q2_0');

        let processRadioButtonForRealValues = function () {
            if (inaccurateRadioButton[0].checked) {
                realValues.hide();
                realValues.find('input').prop('required', true);
                realValues.removeClass('d-none');
                realValues.fadeIn();
            } else {
                realValues.find('input').prop('required', false);
                realValues.fadeOut();
            }
        }

        processRadioButtonForRealValues();

        $('input[type="radio"]').change(processRadioButtonForRealValues);

        // Handle successful form submission
        form.addEventListener('ajax:success', function () {
            form.outerHTML = document.getElementById('manager_success').innerHTML;
        })

        // Handle failed form submission
        form.addEventListener('ajax:error', function (e) {
            processJsonErrors('manager', e)
        });
    }
}