export default function apply() {
    let form = document.getElementById('application-form');
    if (form) {
        let professionSelect = $('#professional_profession');
        professionSelect.change(function () {
            updateQuestionsList(this.value, true);
        });
        if (professionSelect.val() != null) {
            updateQuestionsList(professionSelect.val());
        }
    }
}

// updateQuestionsList takes the profession type, and shows only the appropriate
// questions for that profession. The interactive parameter should be true when responding
// to a user action, and false otherwise, such as on first page load; this means we don't
// unnecessarily fade.
function updateQuestionsList(profession, interactive) {
    let pQs = $('#profession-questions');
    let pQSets = $('.profession-questions-set');
    let alsoColourist = $('#alsoColourist');

    // out specifies fadeOut as opposed to fadeIn
    // cb is a callback function
    $.fn.fadeIfInteractive = function (out, cb = function () {
    }) {
        if (interactive) {
            this.animate({opacity: (out ? "hide" : "show")}, 400).promise().done(cb);
        } else {
            out ? this.hide() : this.show();
            cb();
        }
        return this;
    };

    let fadeInCorrect = function () {
        // fadeIn the right one
        $('#no-profession').fadeIfInteractive(true);
        let correctSet = $('#profession-questions-' + profession);
        correctSet.fadeIfInteractive(false);
        correctSet.find('input').prop('disabled', false).prop('required', true);

        if (profession === 'hairdresser') {
            alsoColourist.removeClass('d-none').fadeIfInteractive(false);
        } else if (alsoColourist.is(':visible')) {
            alsoColourist.fadeIfInteractive(true);
        }
    }

    // only bother switching to jQuery hiding if we've not already done so
    // pQs starts as d-none !important
    if (pQs.data('switchedHide') == null) {
        // hide them with inline styles, then remove Bootstrap's !important hide
        alsoColourist.hide().removeClass('d-none');
        pQSets.hide().removeClass('d-none');
        pQs.data('switchedHide', true);
        fadeInCorrect();
    } else {
        // don't show irrelevant sets
        pQSets.find('input').prop('disabled', true).prop('required', false);
        pQSets.fadeIfInteractive(true, fadeInCorrect);
    }
}