let imagesLoaded = require('imagesloaded');
let Masonry = require('masonry-layout');

export default function masonry() {
    let grid = document.querySelector('.masonry-grid');

    if (grid) {
        let msnry = new Masonry(grid, {
            itemSelector: ".card",
            columnWidth: ".card-sizer",
            gutter: '.gutter-sizer',
            percentPosition: true,
            horizontalOrder: true
        });

        imagesLoaded(grid).on('progress', function () {
            // layout Masonry after each image loads
            msnry.layout();
        });
    }
}